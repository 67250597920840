<script setup>
import { ref, computed, onMounted, reactive } from 'vue';
import Select from './../admin/components/Select.vue';
import axios from 'axios';
import { isLocked, countries, meta, headingSizes, iconSizes, getSize, defaultFormData, save, fetchIP, isDesktop, openWhatsapp } from './store'

const props = defineProps({
    widget: {
        type: Object,
        default: () => { }
    }
});

const whatsapp = computed(() => props.widget.config.whatsapp || {});
const icon = computed(() => props.widget.config.icon || {});
const cta = computed(() => props.widget.config.cta || {});
const cf7 = computed(() => props.widget.config.cf7 || {});
const form = computed(() => props.widget.config.form || {});

const greetings = computed(() => props.widget.config.greetings || {});
const simple = computed(() => greetings.value.templates.simple || {});
const wave = computed(() => greetings.value.templates.wave || {});
const whatsappNumber = computed(() => whatsapp.value.country_code + whatsapp.value.number || '');

const iconPosition = computed(() => {
    if (icon.position !== 'custom') return {};

    return {
        top: icon.position_custom?.top ? icon.position_custom?.top + 'px !important' : 'auto',
        bottom: icon.position_custom?.bottom ? icon.position_custom?.bottom + 'px !important' : 'auto',
        left: icon.position_custom?.left ? icon.position_custom?.left + 'px !important' : 'auto',
        right: icon.position_custom?.right ? icon.position_custom?.right + 'px !important' : 'auto',
    };
})



// When to show form.
const state = reactive({
    shown: false,
    form: false,
    greetings: false,
    greetingsVisible: true,
    formHtml: '',
    sent: false,
    errors: [],
})


const initCF7 = () => {
    // If cf7 form, then load it.
    if (form.value.mode === 'cf7') {
            setTimeout(() => {
                // console.log(document.querySelector('.formychat-widget-custom-form form'));
                try {
                    wpcf7.reset( document.querySelector('.formychat-widget-custom-form form') );
                } catch (ev) {
                    wpcf7.init( document.querySelector('.formychat-widget-custom-form form') );
                }
            }, 0)
        }
}

// Show form on delay.
const showWidget = () => {
    setTimeout(() => {
        state.shown = true;
        state.greetings = true;

        // Show form, if form is requested to show by default.
        if (form.value.open_by_default) {
            state.form = true;
            initCF7()
            state.greetingsVisible = false;
        }

    }, props.widget.config.icon.has_delay ? (props.widget.config.icon.delay * 1000) : 0)
}
onMounted(showWidget)

/**
 * Form related functions.
 */
const hideForm = () => {
    state.form = false;
    setTimeout(() => { state.greetingsVisible = true; }, state.greetings ? 210 : 0)
}


const showForm = () => {
    state.greetingsVisible = false;
    state.errors = [];
    setTimeout(() => { state.form = true;
        initCF7();
     }, state.greetings ? 210 : 0)
}

/**
 * Greetings related functions.
 */
const clickedGreetings = () => {
    if (!state.greetings) return;

    if (greetings.value.on_click === 'show_form') {
        showForm()
    } else {
        if (isLocked) return;

        openWhatsapp(whatsappNumber.value, '', isDesktop() && whatsapp.value.web_version, whatsapp.value.new_tab)
    }
}

const theForm = ref(null)
onMounted(() => {
    // Anywhere clicked but theForm, will close the form.
    window.addEventListener('click', (e) => {
        // Bail, if form is already hidden.
        if (!state.form) return;

        if (!theForm.value || !state.form) return;

        if (!theForm.value.contains(e.target)) {
            hideForm()
        }
    })

    // Anywhere pressed ESC key, will close the form.
    window.addEventListener('keydown', (e) => {
        if (e.key === 'Escape') {
            hideForm()
        }
    })

    // Load form, if form.mode is cf7
    if (form.value.mode === 'cf7') {
        loadCF7Form()
    }

    // On submit formychat-widget-custom-form form.
    // if (form.value.mode === 'cf7') {
    //     // console.log(wpcf7);
        
    //     // setTimeout(() => {
    //     //     const customForm = document.querySelector('.formychat-widget-custom-form form');
    //     //     console.log('Form:', customForm);
        
    //     //     customForm.addEventListener('submit', (e) => {
    //     //         e.preventDefault()
    //     //         console.log('Form submitted');
    //     //     })
    //     // }, 50 )
    //     document.addEventListener('submit', (e) => {
    //         // If formychat-widget-custom-form form is submitted.
    //         if (e.target.closest('.formychat-widget-custom-form form')) {
    //             e.preventDefault()

    //             // Trigger wpcf7submit.
    //             e.target.dispatchEvent(new CustomEvent('wpcf7submit', {
    //                 bubbles: true,
    //                 cancelable: true,
    //             }))
    //         }
    //     })
    // }
})

const loadCF7Form = async () => {
    const data = (await axios.get(formychat_vars.rest_url + '/get-cf7-form/?form_id=' + cf7.value.form_id, {
        headers: {
            'X-WP-Nonce': formychat_vars.rest_nonce,
        }
    })).data

    // Replace \n with actual newlines
    let html = data.replace(/\\n/g, '\n');

    // Replace escaped quotes
    html = html.replace(/\\"/g, '"');

    // Replace escaped forward slashes
    html = html.replace(/\\\//g, '/');

    state.formHtml = html
}

// Form data.
const formData = ref(defaultFormData( form.value.country_code ))

// Format message with data.
const getMessage = computed(() => {
    const data = {
        name: formData.value.name,
        email: formData.value.email,
        phone: (form.value.show_country_code_field ? formData.value.country_code : '') + formData.value.phone_number + '',
        message: formData.value.message,
        site_name: formychat_vars.site.name,
        site_url: formychat_vars.site.url,
        user_id: formychat_vars.user.id || '',
        widget_name: props.widget.name || '',
    }

    // Message template.
    let text = whatsapp.value.message_template || '';

    // Replace placeholders by {key} with data.
    Object.keys(data).forEach(key => {
        text = text.replace(`{${key}}`, data[key])
    })

    return text;
})

// Submit widget form.
const submitForm = async () => {

    const payload = {
        widget_id: props.widget.id,
        source: 'formychat',
        field: {
            name: formData.value.name,
            email: formData.value.email,
            phone: (form.value.show_country_code_field ? formData.value.country_code : '') + formData.value.phone_number + '',
            message: formData.value.message,
        },
        meta: meta,
    }
    // console.log('Saving:', payload);
    // Submit to ajax.
    await save(payload)

    // Open whatsapp with message.
    openWhatsapp(whatsappNumber.value, getMessage.value, isDesktop() && whatsapp.value.web_version, whatsapp.value.new_tab)

    // Reset form data.
    formData.value = defaultFormData(form.value.country_code)

    // Close, if close_on_submit is chosen.
    if (form.value.close_on_submit) {
        hideForm()
    } else {
        clearTimeout(state.showingSent)
        state.sent = true;
        state.errors = [];

        state.showingSent = setTimeout(() => {
            state.sent = false;
        }, 5000)
    }
}

</script>
<template>
    <div class="formychat-widget fixed"
        :class="{ 'formychat-widget-right': icon.position === 'right', 'formychat-widget-left': icon.position === 'left' }"
        :style="iconPosition">
        <!-- form  -->
        <transition name="form_slide">
            <div v-if="state.form" ref="theForm" class="formychat-widget-form"
                :style="{
                    fontFamily: form.font_family + ' !important',
                    width: getSize(form.size, form.size_custom, { small: '280px', medium: '320px', large: '360px' }) + ' !important',
                }">
                <!-- title   -->
                <div class="formychat-widget-form-title"
                    :style="{ color: form.text_color, background: form.background_color }">
                    <span>{{ form.title }}</span>
                    <div @click="hideForm()">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
                        </svg>
                    </div>
                </div>
                <!-- header  -->
                <div class="formychat-widget-form-header" v-if="form.subtitle"> {{ form.subtitle }} </div>
                <!-- scf form fields  -->
                <form @submit.prevent="submitForm" v-if="form.mode == 'formychat'">
                    <div class="formychat-widget-form-fields">
                        <input type="text" required v-model="formData.name" name="name" placeholder="Enter your name"
                            class="formychat-widget-form-fields-input" />
                        <input type="email" required v-model="formData.email" name="email"
                            placeholder="Enter your email" class="formychat-widget-form-fields-input" />
                        <!-- country code  -->
                        <div v-if="form.show_country_code_field" style="width: 100% !important"
                            class="relative flex items-center border border-solid border-gray-300 rounded-md gap-0 focus:border-gray-600">
                            <Select name="country_code" v-model="formData.country_code" :options="countries"
                                class="text-sm border-0 ring-0 rounded bg-white px-1 child:gap-1 child:px-0.5" white />
                            <input type="text" pattern="[0-9]{7,15}" required v-model="formData.phone_number"
                                name="phone_number" tabindex="-1" placeholder="Enter your phone"
                                class="formychat-widget-form-fields-input w-full focus:shadow-none"
                                style="border: 0 !important; padding-left: 0 !important;" />
                        </div>
                        <input v-else type="text" pattern="[\+0-9]{7,15}" required v-model="formData.phone_number"
                            name="phone_number" placeholder="Enter your phone"
                            class="formychat-widget-form-fields-input" />
                        <textarea type="text" v-model="formData.message" name="message" placeholder="Enter your message"
                            rows="2" class="formychat-widget-form-fields-input"></textarea>
                        <!-- footer  -->
                        <div class="formychat-widget-form-footer" v-if="form.footer"> {{ form.footer }} </div>
                    </div>
                    <!-- success message  -->
                    <transition name="bounce">
                        <div class="formychat-widget-form-thank-you" v-if="state.sent"> Thank you for your message.
                            <br /> It has been sent. </div>
                    </transition>
                    <div class="formychat-widget-form-submit">
                        <button type="submit" :style="{ color: form.text_color, background: form.background_color }">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                <path
                                    d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
                            </svg> {{ form.submit }} </button>
                    </div>
                </form>
                <!-- cf7 form  -->
                <div v-else>
                    <div class="formychat-widget-custom-form"
                        :style="`--form-color: ${form.text_color}; --form-bg: ${form.background_color}; --form-font: ${form.font_family}`">
                        <div v-if="state.formHtml" v-html="state.formHtml"></div>
                        <div v-else class="flex items-center justify-center mb-5 text-center">Please wait...</div>
                    </div>
                    <!-- footer  -->
                    <div class="formychat-widget-form-footer mb-6" v-if="form.footer_text"> {{ form.footer_text }}
                    </div>
                </div>
            </div>
        </transition>
        <!-- greetings  -->
        <transition name="form_slide">
            <div class="formychat-widget-greetings"
                v-if="greetings.enabled && state.greetings && state.greetingsVisible" @click.prevent="clickedGreetings">
                <!-- simple  -->
                <div v-if="greetings.template === 'simple'" class="formychat-widget-greetings-simple"
                    :style="`background: ${simple.background_color} !important; ` + (simple.font_family == 'sans-serif' ? '-apple-system, sans-serif' : `font-family: '${simple.font_family}' !important;`)">
                    <!-- close  -->
                    <div @click.stop="state.greetings = false" class="formychat-widget-greetings-simple-close">
                        <svg viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M5.84006 0.909735C5.89007 0.861332 5.92996 0.803424 5.95742 0.739389C5.98487 0.675355 5.99934 0.606476 5.99998 0.536772C6.00061 0.467068 5.98741 0.397935 5.96112 0.333407C5.93484 0.268878 5.89601 0.210247 5.8469 0.160933C5.79779 0.11162 5.73937 0.0726119 5.67507 0.0461858C5.61077 0.0197597 5.54187 0.00644469 5.47238 0.00701731C5.40289 0.00758993 5.33422 0.0220389 5.27036 0.0495211C5.2065 0.0770034 5.14873 0.116969 5.10043 0.167085L3.00296 2.27044L0.906189 0.167085C0.858279 0.115507 0.800504 0.0741387 0.736311 0.0454462C0.672117 0.0167537 0.60282 0.00132536 0.532553 8.16984e-05C0.462287 -0.00116197 0.39249 0.0118046 0.327328 0.0382075C0.262165 0.0646105 0.202971 0.103909 0.153277 0.153759C0.103584 0.203609 0.0644082 0.262988 0.0380879 0.328355C0.0117676 0.393723 -0.00115833 0.463739 8.14426e-05 0.534226C0.00132121 0.604713 0.0167013 0.674228 0.0453039 0.738623C0.0739065 0.803018 0.115146 0.860975 0.166562 0.909036L2.26194 3.01309L0.165166 5.11645C0.0727267 5.21597 0.0224017 5.34759 0.0247938 5.4836C0.0271859 5.6196 0.0821082 5.74936 0.17799 5.84554C0.273871 5.94172 0.403226 5.99682 0.538802 5.99922C0.674377 6.00162 0.805589 5.95114 0.904793 5.85841L3.00296 3.75505L5.09974 5.85911C5.19894 5.95184 5.33015 6.00232 5.46573 5.99992C5.6013 5.99752 5.73066 5.94242 5.82654 5.84624C5.92242 5.75006 5.97734 5.6203 5.97974 5.4843C5.98213 5.34829 5.9318 5.21667 5.83936 5.11715L3.74399 3.01309L5.84006 0.909735Z"
                                fill="#BDBDBD" />
                        </svg>
                    </div>
                    <!-- heading  -->
                    <div class="formychat-widget-greetings-simple-heading"
                        :style="{ color: simple.heading_color, fontSize: getSize(simple.heading_size, simple.heading_size_custom) }">
                        {{ simple.heading }} </div>
                    <!-- message  -->
                    <div class="formychat-widget-greetings-simple-message"
                        :style="{ color: simple.message_color, fontSize: getSize(simple.message_size, simple.message_size_custom) }">
                        {{ simple.message }} </div>
                    <!-- shapes for style 2  -->
                    <div class="formychat-widget-greetings-simple-cloud" v-if="greetings.style === 2">
                        <svg width="121" height="27" :style="{ color: simple.background_color }" viewBox="0 0 121 27"
                            fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M120.167 26.9229H0.444336C6.30566 11.199 21.4561 0 39.2227 0C53.7012 0 66.4424 7.43732 73.835 18.7C79.0801 16.2946 84.9141 14.9537 91.0615 14.9537C102.41 14.9537 112.692 19.5233 120.167 26.9229Z"
                                fill="currentColor" />
                        </svg>
                    </div>
                </div>
                <!-- wave  -->
                <div v-if="greetings.template === 'wave'"
                    :style="wave.font_family == 'sans-serif' ? '-apple-system, sans-serif' : `font-family: '${wave.font_family}' !important`">
                    <div v-if="greetings.style === 1"
                        class="formychat-widget-greetings-wave formychat-widget-greetings-wave-1"
                        :style="{ background: wave.background_color, color: wave.color, fontFamily: wave.font_family }">
                        <!-- close  -->
                        <div @click.stop="state.greetings = false" class="formychat-widget-greetings-wave-close">
                            <svg viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M5.84006 0.909735C5.89007 0.861332 5.92996 0.803424 5.95742 0.739389C5.98487 0.675355 5.99934 0.606476 5.99998 0.536772C6.00061 0.467068 5.98741 0.397935 5.96112 0.333407C5.93484 0.268878 5.89601 0.210247 5.8469 0.160933C5.79779 0.11162 5.73937 0.0726119 5.67507 0.0461858C5.61077 0.0197597 5.54187 0.00644469 5.47238 0.00701731C5.40289 0.00758993 5.33422 0.0220389 5.27036 0.0495211C5.2065 0.0770034 5.14873 0.116969 5.10043 0.167085L3.00296 2.27044L0.906189 0.167085C0.858279 0.115507 0.800504 0.0741387 0.736311 0.0454462C0.672117 0.0167537 0.60282 0.00132536 0.532553 8.16984e-05C0.462287 -0.00116197 0.39249 0.0118046 0.327328 0.0382075C0.262165 0.0646105 0.202971 0.103909 0.153277 0.153759C0.103584 0.203609 0.0644082 0.262988 0.0380879 0.328355C0.0117676 0.393723 -0.00115833 0.463739 8.14426e-05 0.534226C0.00132121 0.604713 0.0167013 0.674228 0.0453039 0.738623C0.0739065 0.803018 0.115146 0.860975 0.166562 0.909036L2.26194 3.01309L0.165166 5.11645C0.0727267 5.21597 0.0224017 5.34759 0.0247938 5.4836C0.0271859 5.6196 0.0821082 5.74936 0.17799 5.84554C0.273871 5.94172 0.403226 5.99682 0.538802 5.99922C0.674377 6.00162 0.805589 5.95114 0.904793 5.85841L3.00296 3.75505L5.09974 5.85911C5.19894 5.95184 5.33015 6.00232 5.46573 5.99992C5.6013 5.99752 5.73066 5.94242 5.82654 5.84624C5.92242 5.75006 5.97734 5.6203 5.97974 5.4843C5.98213 5.34829 5.9318 5.21667 5.83936 5.11715L3.74399 3.01309L5.84006 0.909735Z"
                                    fill="#BDBDBD" />
                            </svg>
                        </div>
                        <!-- Main icon - Before heading  -->
                        <div v-if="wave.show_icon && !['after_heading', 'after_message'].includes(wave.icon_position)"
                            class="formychat-widget-greetings-wave-1-icon"> <img :src="wave.icon_url"
                                class="w-full mx-auto" /> </div>
                        <!-- heading  -->
                        <div class="formychat-widget-greetings-wave-heading" v-if="wave.heading"
                            :style="{ color: wave.heading_color, fontSize: getSize(wave.heading_size, wave.heading_size_custom) }">
                            {{ wave.heading }} </div>
                        <!-- Main icon - Before heading  -->
                        <div v-if="wave.show_icon && wave.icon_position == 'after_heading'"
                            class="formychat-widget-greetings-wave-1-icon"> <img :src="wave.icon_url"
                                class="w-full mx-auto" /> </div>
                        <!-- message  -->
                        <div class="formychat-widget-greetings-wave-message"
                            :style="{ color: wave.message_color, fontSize: getSize(wave.message_size, wave.message_size_custom) }">
                            {{ wave.message }} </div>
                        <!-- Main icon - Before heading  -->
                        <div v-if="wave.show_icon && wave.icon_position == 'after_message'"
                            class="formychat-widget-greetings-wave-1-icon"> <img :src="wave.icon_url"
                                class="w-full mx-auto" /> </div>
                        <!-- CTA  -->
                        <div v-if="wave.show_cta" class="formychat-widget-greetings-wave-1-cta"
                            :style="{ background: wave.cta_background_color, color: wave.cta_text_color }">
                            <div><img :src="wave.cta_icon_url" class="w-full mx-auto" /> </div>
                            <div v-if="wave.cta_text">{{ wave.cta_text }}</div>
                        </div>
                    </div>
                    <div v-if="greetings.style === 2"
                        class="formychat-widget-greetings-wave formychat-widget-greetings-wave-2"
                        :style="{ background: wave.background_color, fontFamily: wave.font_family }">
                        <!-- close  -->
                        <div @click.stop="state.greetings = false" class="formychat-widget-greetings-wave-close">
                            <svg viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M5.84006 0.909735C5.89007 0.861332 5.92996 0.803424 5.95742 0.739389C5.98487 0.675355 5.99934 0.606476 5.99998 0.536772C6.00061 0.467068 5.98741 0.397935 5.96112 0.333407C5.93484 0.268878 5.89601 0.210247 5.8469 0.160933C5.79779 0.11162 5.73937 0.0726119 5.67507 0.0461858C5.61077 0.0197597 5.54187 0.00644469 5.47238 0.00701731C5.40289 0.00758993 5.33422 0.0220389 5.27036 0.0495211C5.2065 0.0770034 5.14873 0.116969 5.10043 0.167085L3.00296 2.27044L0.906189 0.167085C0.858279 0.115507 0.800504 0.0741387 0.736311 0.0454462C0.672117 0.0167537 0.60282 0.00132536 0.532553 8.16984e-05C0.462287 -0.00116197 0.39249 0.0118046 0.327328 0.0382075C0.262165 0.0646105 0.202971 0.103909 0.153277 0.153759C0.103584 0.203609 0.0644082 0.262988 0.0380879 0.328355C0.0117676 0.393723 -0.00115833 0.463739 8.14426e-05 0.534226C0.00132121 0.604713 0.0167013 0.674228 0.0453039 0.738623C0.0739065 0.803018 0.115146 0.860975 0.166562 0.909036L2.26194 3.01309L0.165166 5.11645C0.0727267 5.21597 0.0224017 5.34759 0.0247938 5.4836C0.0271859 5.6196 0.0821082 5.74936 0.17799 5.84554C0.273871 5.94172 0.403226 5.99682 0.538802 5.99922C0.674377 6.00162 0.805589 5.95114 0.904793 5.85841L3.00296 3.75505L5.09974 5.85911C5.19894 5.95184 5.33015 6.00232 5.46573 5.99992C5.6013 5.99752 5.73066 5.94242 5.82654 5.84624C5.92242 5.75006 5.97734 5.6203 5.97974 5.4843C5.98213 5.34829 5.9318 5.21667 5.83936 5.11715L3.74399 3.01309L5.84006 0.909735Z"
                                    fill="#BDBDBD" />
                            </svg>
                        </div>
                        <!-- Main icon - Before CTA  -->
                        <div v-if="wave.show_icon && wave.icon_position !== 'after_cta'"
                            class="formychat-widget-greetings-wave-2-icon"> <img :src="wave.icon_url"
                                class="w-full mx-auto" /> </div>
                        <!-- CTA  -->
                        <div v-if="wave.show_cta" class="formychat-widget-greetings-wave-2-cta"
                            :style="{ background: wave.cta_background_color }">
                            <!-- heading  -->
                            <div class="formychat-widget-greetings-wave-2-cta-heading" v-if="wave.cta_heading" x-text=""
                                :style="{ color: wave.cta_heading_color, fontSize: getSize(wave.cta_heading_size, wave.cta_heading_size_custom) }">
                                {{ wave.cta_heading }} </div>
                            <!-- message  -->
                            <div class="formychat-widget-greetings-wave-2-cta-message" v-if="wave.cta_message"
                                :style="{ color: wave.cta_message_color, fontSize: getSize(wave.cta_message_size, wave.cta_message_size_custom) }">
                                {{ wave.cta_message }} </div>
                        </div>
                        <!-- Main icon - After CTA  -->
                        <div v-if="wave.show_icon && wave.icon_position === 'after_cta'"
                            class="formychat-widget-greetings-wave-2-icon"> <img :src="wave.icon_url"
                                class="w-full mx-auto" /> </div>
                    </div>
                </div>
            </div>
        </transition>
        <!-- icon box  -->
        <transition name="bounce">
            <div v-if="state.shown" tabindex="0" class="formychat-widget-handler group"
                :class="{ 'reversed': icon.position === 'left' }" @click.prevent="state.form ? hideForm() : showForm()">
                <!-- cta  -->
                <transition name="bounce">
                    <div v-if="cta.enabled" class="formychat-widget-handler-cta"
                        :style="{ color: cta.color, background: cta.background_color, fontSize: getSize(cta.size, cta.size_custom, headingSizes) }">
                        {{ cta.text }} </div>
                </transition>
                <!-- icon  -->
                <div class="formychat-widget-handler-icon group-hover:opacity-95 group-hover:scale-110"
                    :style="{ width: getSize(icon.size, icon.size_custom, iconSizes), height: getSize(icon.size, icon.size_custom, iconSizes) }">
                    <img :src="icon.image_url || Image('whatsapp.svg')">
                </div>
            </div>
        </transition>
    </div>
</template>