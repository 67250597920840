<script setup>
import { ref, computed, getCurrentInstance } from 'vue';
const props = defineProps({
    modelValue: {
        type: Number,
    },
    type: {
        type: String,
        default: 'text',
    },
    error: {
        type: Boolean,
        default: false,
    },
    id: {
        type: String,
        default: '',
    },
    white: {
        type: Boolean,
        default: false,
    },
})

const isFocused = ref(false);
const id = computed( () => {
    const instance = getCurrentInstance();
    return props.id || instance.parent.props.id || 'scf-element-' + instance.parent.uid;
})
</script>

<template>
    <!-- <div class="relative w-full h-10 rounded"> -->
        <input :type="props.type" @focus="isFocused = true" @blur="isFocused = false" v-model="props.modelValue" class="h-10 focus:ring-[2px]  text-[14px] shadow-sm hover:shadow border-0 ring-1 relative appearance-none focus:outline-none  ring-gray-200 focus:bg-white transition w-full px-4 rounded text-gray-700 disabled:opacity-60 disabled:bg-gray-200 disabled:ring-gray-300 placeholder:text-gray-400 placeholder:text-[13px]"
        v-bind="$attrs" :id="id"  :class="{'bg-gray-50' : ! props.white, 'bg-white' : props.white, 'focus:ring-indigo-400' : ! props.error, 'focus:ring-red-500' : props.error}"/>
        <!-- <div class="absolute top-full -translate-y-[1.5px] left-1/2 rounded -translate-x-1/2 h-[2px] mx-auto duration-200 transition-all" :class="{'max-width opacity-100 bg-indigo-500' : isFocused  && ! props.error, 'w-0 opacity-0 delay-100' : !isFocused && ! props.error, 'opacity-100 bg-red-500 w-full' : props.error }"></div> -->
    <!-- </div> -->
</template>

<style scoped>
.max-width {
    width: calc(100% - 2px);
}
</style>