<script setup>
import { computed } from 'vue';
const props = defineProps({
    outline: {
        type: Boolean,
    },
    a : {
        type: Boolean,
        default: false
    },
    color: {
        type: String,
        default: 'primary'
    }
})

const colors = {
    primary: {
        solid: 'bg-indigo-600 text-white border-indigo-600 hover:border-indigo-500',
        outline: 'bg-transparent text-indigo-600 border-indigo-600  hover:bg-indigo-600 hover:text-white'
    },
    secondary: {
        solid: 'bg-gray-600 text-white border-gray-600 hover:border-gray-500',
        outline: 'bg-transparent text-gray-600 border-gray-400 hover:bg-gray-600 hover:text-white hover:border-gray-600'
    },
    danger: {
        solid: 'bg-red-600 text-white border-red-600  hover:border-red-500',
        outline: 'bg-transparent text-red-600 border-red-600 hover:bg-red-600 hover:text-white'
    },
}

const color = computed(() => colors[props.color])
</script>
<template>
    <component
        :is="a ? 'a' : 'button'"
        class="relative group px-6 h-10 flex items-center gap-2 border rounded transition focus:ring-0 active:grayscale-100 active:opacity-95 overflow-hidden"
        :class="{
            [ color.solid ] : !outline,
            [ color.outline ] : outline,
        }" 
        v-bind="$attrs">
        <div :class="{ [color.outline] : outline, 'duration-300' : outline, 'bg-white opacity-0 group-hover:opacity-10 duration-200 group-hover:scale-110' : !outline }" class="absolute z-0 h-full w-full scale-0 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  transition"></div>
        <div class="z-10 flex items-center justify-center gap-2 whitespace-nowrap"><slot /></div>
    </component>
</template>