import { save, openWhatsapp, meta, isDesktop } from './../store.js';

(function(){
    /** Catch CF7 Form */
    const catchForm = async ( e ) => {

        // Bail if not successful
        if ( e.detail.apiResponse.status !== 'mail_sent' ) return;

        const settings = e.detail.apiResponse.formychat || {}

        // Collect lead data
        const inputs = e.detail.inputs || []

        // Save inputs.
        let data = {
            source: 'cf7',
            cf7_id: e.detail.contactFormId,
            field: Object.fromEntries(inputs.map(input => [input.name, input.value])),
            meta: meta.value,
        }

        console.log('Saving', data, settings);
        await save(data);

        // Bail, if settings not found or settings.status is not 'on'
        if ( !settings || settings.status !== 'on' ) return;

        // Open whatsapp.
        let message = settings.message || '';
        // Prepare message
        inputs.forEach(input => {
            message = message.replace(`[${input.name}]`, input.value)
        })

        console.log('Settings', settings);
        
        openWhatsapp( ( settings.country_code + settings.number + '' ), message, isDesktop(), 'on' == settings.new_tab );

    }

    document.addEventListener('wpcf7submit', catchForm);
})()