<script setup>
import Button from './Button.vue';
import { computed } from 'vue';
const props = defineProps({
    text: {
        type: String,
        default: 'Ultimate'
    }
})

const isLocked = computed( () => !formychat_admin_vars.is_premium )
</script>

<template>
    <Button v-if="isLocked" class="formychat-locked" :class="{ 'rounded-full px-0 w-5 h-5 flex items-center justify-center' : !props.text, 'px-1.5 h-6 text-[12px] child:gap-1 rounded-2xl text-xs font-medium' : props.text  }"
     v-bind="$attrs">
       
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" class="fill-current w-[11px] -mt-0.5" viewBox="0 0 30 30">
            <path d="M 15 2 C 11.145666 2 8 5.1456661 8 9 L 8 11 L 6 11 C 4.895 11 4 11.895 4 13 L 4 25 C 4 26.105 4.895 27 6 27 L 24 27 C 25.105 27 26 26.105 26 25 L 26 13 C 26 11.895 25.105 11 24 11 L 22 11 L 22 9 C 22 5.2715823 19.036581 2.2685653 15.355469 2.0722656 A 1.0001 1.0001 0 0 0 15 2 z M 15 4 C 17.773666 4 20 6.2263339 20 9 L 20 11 L 10 11 L 10 9 C 10 6.2263339 12.226334 4 15 4 z"></path>
        </svg>
        <span v-if="props.text" v-html="props.text"></span>
    </Button>
</template>